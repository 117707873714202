import { css } from 'lit';
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import { NetworkOnly, StaleWhileRevalidate } from 'workbox-strategies';
import { cleanupOutdatedCaches, matchPrecache, precacheAndRoute } from 'workbox-precaching';
import { NavigationRoute, registerRoute } from 'workbox-routing';
import { ExpirationPlugin } from 'workbox-expiration';
import { RouteHandlerCallbackOptions, clientsClaim, skipWaiting } from 'workbox-core';
import * as navigationPreload from 'workbox-navigation-preload';
import { PrecacheEntry } from 'workbox-precaching/_types';
import { googleFontsCache } from 'workbox-recipes';

const FALLBACK_HTML_URL = '/offline.html';
const SECONDS_PER_DAY = 60 * 60 * 24;

navigationPreload.enable();

/** Hacky fix to type complaints */
declare global {
	interface Window {
		__WB_MANIFEST: (string | PrecacheEntry)[];
	}
}

precacheAndRoute(self.__WB_MANIFEST);

cleanupOutdatedCaches();

/** @see https://stackoverflow.com/questions/58027359/difference-between-clientsclaim-and-skipwaiting */
skipWaiting();
clientsClaim();

registerRoute(
	new RegExp('/img/'),
	new StaleWhileRevalidate({
		cacheName: 'images',
		plugins: [new ExpirationPlugin({ maxAgeSeconds: SECONDS_PER_DAY * 30 })],
	}),
);

registerRoute(
	new NavigationRoute(async (params: RouteHandlerCallbackOptions): Promise<Response> => {
		try {
			return await new NetworkOnly().handle(params);
		} catch (error) {
			const offlinePage = await matchPrecache(FALLBACK_HTML_URL);
			return offlinePage ?? Response.error();
		}
	}),
);

// https://developers.google.com/web/tools/workbox/modules/workbox-recipes

googleFontsCache();
